<template>
  <div :class="`circleProgress id_${id}`">
    <svg class="progress-ring" :width="sizeSVG" :height="sizeSVG">
      <circle
        class="progress-ring__circle_bg"
        :stroke="`${background ? background : 'currentColor'}`"
        stroke-width="4"
        fill="transparent"
        :r="radiusCircle"
        :cx="cxCircle"
        :cy="cyCircle"
      />
      <circle
        class="progress-ring__circle"
        :stroke="color || $dcolor"
        stroke-width="4"
        fill="transparent"
        :r="radiusCircle"
        :cx="cxCircle"
        :cy="cyCircle"
      />
    </svg>
    <div class="circleProgressLabel" v-html="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: ["progress", "size", "label", "stroke", "color", "background"],
  data: function() {
    return {
      id: Go.uuid(),
    };
  },
  computed: {
    sizeSVG: function() {
      return this.size || 120;
    },
    radiusCircle: function() {
      return this.sizeSVG / 2 - 4;
    },
    cxCircle: function() {
      return this.sizeSVG / 2;
    },
    cyCircle: function() {
      return this.sizeSVG / 2;
    },
  },
  methods: {
    update: async function(progress) {
      var circle = await Go.awaitUntilElement(`.id_${this.id} .progress-ring__circle`);

      if (!circle) return;

      var radius = circle.r.baseVal.value;
      var circumference = radius * 2 * Math.PI;

      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = `${circumference}`;

      function setProgress(percent) {
        const offset = circumference - (percent / 100) * circumference;
        circle.style.strokeDashoffset = offset;
      }

      setProgress(progress);
    },
  },
  mounted: async function() {
    await this.sleep(300);
    this.update(this.progress);
  },
  watch: {
    progress: function(newProgress) {
      this.update(newProgress);
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  // axis compensation
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circleProgress {
  position: relative;
  @include Flex(inherit, center, center);
}

.circleProgressLabel {
  position: absolute;
  text-align: center;
}

.progress-ring__circle_bg {
  color: rgba($color: #000000, $alpha: 0.3);
}
</style>
